.float-search{
	position:fixed;
	width:60px;
	height:60px;
	bottom:120px;
	right:40px;
    background: linear-gradient(60deg, #382cdd 0%, #729efd 100%);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float-search{
	margin-top:22px;
}
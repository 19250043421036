.content {
    height: 100% !important;
}

.container-fluid {
    padding-top: 80px;
}

.content-wrapper {
    height: 100% !important;
}


.sidebar {
    background: linear-gradient(109.6deg, #2e2b2c 11.2%, #313030 91.1%);
    padding: 0;
    height: 100% !important;

}

.brand-link {
    border-bottom: 1px solid #2e2b2c !important;
    ;
}

.app-header {
    background-color: #2e2b2c !important;
    margin: 0;
    width: 100% !important;
}

.text-center {
    text-align: center;
}

.icon-user {
    font-size: 50px;
    padding: 10px;
}

.app-user-info {
    color: #fff;
    background-color: #838282;
    padding: 10px;
    text-align: center !important;
    justify-content: center !important;
}

.version {
    font-size: 15px;
    color: #acacac;
}

.user-info {
    width: 100vh;
    margin-left: 20px;
}

.content {
    padding: 30px;
}

.btn-space {
    margin-left: 15px;
}

.field-required {
    color: red;
    font-weight: bold;
}

.spinner-center {
    justify-content: center;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background: #fff;
    width: 100%;
    color: #000000;
}

.sidebar-footer {
    margin-top: auto !important;
    ;
    /* Empurra o texto para o final da barra lateral */
    padding-top: 10px !important;
    background-color: #ff96c0;
    width: 100% !important;
}


.custom-input {
    border: 1px #d3d3d3 solid;
    padding: 18px 12px;
    background-color: white;
    height: 22px;
    width: 100%;
}

.custom-input:focus {
    outline: none;
}

.form-control {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.textarea {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.btn {
    border-radius: 50px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
}

.navbar {
    background-color: #fff;
    color: #333;
    position: fixed;
    top: 0;
    width: 100%;
}
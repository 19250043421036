.float-refresh{
	position:fixed;
	width:60px;
	height:60px;
	bottom:120px;
	right:40px;
    background: linear-gradient(60deg, #1d8308 0%, #1fe629 100%);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float-refresh{
	margin-top:22px;
}
.star-button {
    background-color: #f94f92 !important;
    border-color: #f94f92 !important;
    border-radius: 50px !important;
}

.round-border-button {
    padding: 10px 20px;
    background-color: #f384b1;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    width: 100%;
}

.round-border-button:hover {
    background-color: #ff6b9a;
    transform: scale(1.1);
}